import get from 'lodash.get';

export const PantDetailsForm = {
  title: 'Pant details',
  display: 'Pant',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        // {
        //   label: 'Price',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['price'],
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['fabricRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Type',
          type: 'basicRadio',
          isRequired: true,
          key: ['type'],
          options: [
            {
              key: 'suit_pant',
              label: 'Suit pant',
            },
            {
              key: 'chino',
              label: 'Chino',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Waist option 1',
          type: 'basicRadio',
          key: ['waistOption1'],
          isRequired: true,
          options: [
            {
              key: 'std',
              label: 'STD',
            },
            {
              key: 'double_side',
              label: 'Double side',
            },
            {
              key: 'single_side',
              label: 'Single side',
            },
            {
              key: 'sport',
              label: 'Sport',
            },
            {
              key: 'other',
              label: 'Other',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Waist option 2',
          type: 'basicRadio',
          key: ['waistOption2'],
          isRequired: true,
          options: [
            {
              key: 'belt',
              label: 'Belt',
            },
            {
              key: 'side_adjustment',
              label: 'Side adjustment',
            },
            {
              key: 'other',
              label: 'Other',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Waistband extension',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['waistbandExtension'],
          options: [
            {
              key: 'standard',
              label: 'Standard (hook + button)',
            },
            {
              key: 'double_hook',
              label: 'Double hooks (no button)',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Side pockets',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['sidePockets'],
          options: [
            {
              key: 'standard',
              label: 'Standard',
            },
            {
              key: 'straight',
              label: 'Straight',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Pleats',
          type: 'basicRadio',
          isRequired: true,
          key: ['pleats'],
          options: [
            {
              key: 'no_pleats',
              label: 'No pleats',
            },
            {
              key: 'single_pleat',
              label: 'Single pleat',
            },
            {
              key: 'double_pleat',
              label: 'Double pleat',
            },
            {
              key: 'fix',
              label: 'Fix',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Length',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          default: 'long_pant',
          key: ['length'],
          options: [
            {
              key: 'short_pant',
              label: 'Short Pant',
            },
            {
              key: 'long_pant',
              label: 'Long Pant',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Pant cuff',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['pantCuff'],
          options: [
            {
              key: 'no_pant_cuff',
              label: 'No pant cuff',
            },
            {
              key: 'cuff_3_cm',
              label: 'Cuff 3 cm',
            },
            {
              key: 'cuff_4_cm',
              label: 'Cuff 4 cm',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Coin / Watch pocket',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['watchPocket'],
          options: [
            {
              key: 'coin_pocket_6cm',
              label: 'Coin pocket (6cm)',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Button for suspender',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['suspenserButton'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Back pocket',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['backPocket'],
          options: [
            {
              key: 'left_right',
              label: 'Left & right',
            },
            {
              key: 'no_pocket',
              label: 'No back pockets',
            },
            {
              key: 'right',
              label: 'One on the right',
            },
            {
              key: 'left',
              label: 'One on the left',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Back pocket style',
          type: 'basicRadio',
          isRequired: true,
          key: ['backPocketStyle'],
          options: [
            {
              key: 'double_bessom_no_button',
              label: 'Double bessom no button',
            },
            {
              key: 'double_besson_with_button',
              label: 'Double bessom with button and button hole',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Inside phone pocket',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['insidePhonePocket'],
          options: [
            {
              key: 'left',
              label: 'Inside left pocket',
            },
            {
              key: 'right',
              label: 'Inside right pocket',
            },
            {
              key: 'both',
              label: 'Inside both pocket',
            },
            {
              key: 'none',
              label: 'No inside pocket',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Seam satin',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['seamSatin'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Upward side seam satin color',
          isRequired: true,
          placeholder: '-',
          key: ['upwardSideSeamSatinColor'],
          isHiding: (values, name) =>
            get(values, `${name}seamSatin`, '') !== 'yes',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Button ref',
          type: 'select',
          isRequired: true,
          key: ['buttonRef'],
          isHiding: (values, name) =>
            get(values, `${name}waistbandExtension`, '') !== 'standard',
          options: [
            { key: 'fk503202', label: 'Classic black horn (FK503202)' },
            { key: 'fkE03237', label: 'Tinted horn blue (FKE03237)' },
            { key: 'fkE03236', label: 'Tinted horn red (FKE03236)' },
            { key: 'fk743204', label: 'Brown shell (FK743204)' },
            { key: 'fk523212', label: 'Grey shell (FK523212)' },
            { key: 'fk213204', label: 'Silk black (FK213204)' },
            { key: 'fk743203', label: 'White shell (FK743203)' },
            { key: 'fk523205', label: 'Brown horn (FK523205)' },
            {
              key: 'others',
              label: 'Others',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },

        {
          label: 'Other button ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherButtonRef'],
          isHiding: (values, name) =>
            get(values, `${name}waistbandExtension`, '') !== 'standard' ||
            get(values, `${name}buttonRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Lining pant',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['liningPant'],
          options: [
            {
              key: 'half_leg',
              label: 'Half leg (standard)',
            },
            {
              key: 'full_leg',
              label: 'Full leg',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Contrast color',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['contrastColor'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Contrast color ref',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          key: ['contrastColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') !== 'yes',
          options: [
            { key: 'fe029023', label: 'Black silk (FE029023)' },
            { key: 'fe029025', label: 'Blue silk (FE029025)' },
            { key: 'fp010020', label: 'Red suede (FP010020)' },
            { key: 'fp015782', label: 'Dark blue suede (FP015782)' },
            { key: 'fp010043', label: 'Navy blue suede (FP010043)' },
            { key: 'fp011014', label: 'Brown suede (FP011014)' },
            { key: 'fp010114', label: 'Grey suede (FP010114)' },
            { key: 'fe029024', label: 'White silk (FE029024)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other Contrast color ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherContrastColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') !== 'yes' ||
            get(values, `${name}contrastColorRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Contrast position',
          type: 'multiSelect',
          key: ['contrastPositionMulti'],
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') !== 'yes',
          options: [
            // {
            //   key: 'no',
            //   label: 'No',
            // },
            {
              key: 'belt_loop',
              label: 'Belt loop',
            },
            {
              key: 'side_pocket',
              label: 'Side pocket',
            },
            {
              key: 'back_pocket',
              label: 'Back pocket',
            },
            {
              key: 'waistband',
              label: 'Waistband',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },

        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          foldable: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const PantSuitDetailsForm = {
  title: 'Pant details',
  display: 'Pant',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        // {
        //   label: 'Price',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['price'],
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Fabric ref type',
          type: 'basicRadio',
          isRequired: true,
          key: ['fabricRefSuit'],
          options: [
            {
              key: 'same_as_jacket',
              label: 'Same as jacket',
            },
            {
              key: 'other',
              label: 'Others',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Fabric ref',
          isRequired: true,
          placeholder: '-',
          isHiding: (values, name) =>
            get(values, `${name}fabricRefSuit`, '') !== 'other',
          key: ['fabricRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Type',
          type: 'basicRadio',
          isRequired: true,
          key: ['type'],
          options: [
            {
              key: 'suit_pant',
              label: 'Suit pant',
            },
            {
              key: 'chino',
              label: 'Chino',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Waist option 1',
          type: 'basicRadio',
          key: ['waistOption1'],
          default: 'std',
          options: [
            {
              key: 'std',
              label: 'STD',
            },
            {
              key: 'double_side',
              label: 'Double side',
            },
            {
              key: 'single_side',
              label: 'Single side',
            },
            {
              key: 'sport',
              label: 'Sport',
            },
            {
              key: 'other',
              label: 'Other',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Waist option 2',
          type: 'basicRadio',
          key: ['waistOption2'],
          default: 'belt',
          options: [
            {
              key: 'belt',
              label: 'Belt',
            },
            {
              key: 'side_adjustment',
              label: 'Side adjustment',
            },
            {
              key: 'other',
              label: 'Other',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Waistband extension',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['waistbandExtension'],
          options: [
            {
              key: 'standard',
              label: 'Standard (hook + button)',
            },
            {
              key: 'double_hook',
              label: 'Double hooks (no button)',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Side pockets',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['sidePockets'],
          options: [
            {
              key: 'standard',
              label: 'Standard',
            },
            {
              key: 'straight',
              label: 'Straight',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Pleats',
          type: 'basicRadio',
          isRequired: true,
          key: ['pleats'],
          options: [
            {
              key: 'no_pleats',
              label: 'No pleats',
            },
            {
              key: 'single_pleat',
              label: 'Single pleat',
            },
            {
              key: 'double_pleat',
              label: 'Double pleat',
            },
            {
              key: 'fix',
              label: 'Fix',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Length',
          type: 'basicRadio',
          isRequired: true,
          default: 'long_pant',
          key: ['length'],
          options: [
            {
              key: 'short_pant',
              label: 'Short Pant',
            },
            {
              key: 'long_pant',
              label: 'Long Pant',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Pant cuff',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['pantCuff'],
          options: [
            {
              key: 'no_pant_cuff',
              label: 'No pant cuff',
            },
            {
              key: 'cuff_3_cm',
              label: 'Cuff 3 cm',
            },
            {
              key: 'cuff_4_cm',
              label: 'Cuff 4 cm',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Coin / Watch pocket',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['watchPocket'],
          options: [
            {
              key: 'coin_pocket_6cm',
              label: 'Coin pocket (6cm)',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Button for suspender',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['suspenserButton'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Back pocket',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['backPocket'],
          options: [
            {
              key: 'left_right',
              label: 'Left & right',
            },
            {
              key: 'no_pocket',
              label: 'No back pockets',
            },
            {
              key: 'right',
              label: 'One on the right',
            },
            {
              key: 'left',
              label: 'One on the left',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Back pocket style',
          type: 'basicRadio',
          isRequired: true,
          key: ['backPocketStyle'],
          options: [
            {
              key: 'double_bessom_no_button',
              label: 'Double bessom no button',
            },
            {
              key: 'double_besson_with_button',
              label: 'Double bessom with button and button hole',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Inside phone pocket',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['insidePhonePocket'],
          options: [
            {
              key: 'left',
              label: 'Inside left pocket',
            },
            {
              key: 'right',
              label: 'Inside right pocket',
            },
            {
              key: 'both',
              label: 'Inside both pocket',
            },
            {
              key: 'none',
              label: 'No inside pocket',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Seam satin',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['seamSatin'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Upward side seam satin color',
          isRequired: true,
          placeholder: '-',
          key: ['upwardSideSeamSatinColor'],
          isHiding: (values, name) =>
            get(values, `${name}seamSatin`, '') !== 'yes',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Button ref',
          type: 'basicRadio',
          isRequired: true,
          key: ['buttonRefSuit'],
          isHiding: (values, name) =>
            get(values, `${name}waistbandExtension`, '') !== 'standard',
          options: [
            { key: 'same_as_jacket', label: 'Same as jacket' },
            { key: 'other', label: 'Other' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Button ref',
          type: 'select',
          isRequired: true,
          key: ['buttonRef'],
          isHiding: (values, name) =>
            get(values, `${name}waistbandExtension`, '') === 'double_hook' ||
            get(values, `${name}buttonRefSuit`, '') === 'same_as_jacket',
          options: [
            { key: 'fk503202', label: 'Classic black horn (FK503202)' },
            { key: 'fkE03237', label: 'Tinted horn blue (FKE03237)' },
            { key: 'fkE03236', label: 'Tinted horn red (FKE03236)' },
            { key: 'fk743204', label: 'Brown shell (FK743204)' },
            { key: 'fk523212', label: 'Grey shell (FK523212)' },
            { key: 'fk213204', label: 'Silk black (FK213204)' },
            { key: 'fk743203', label: 'White shell (FK743203)' },
            { key: 'fk523205', label: 'Brown horn (FK523205)' },
            {
              key: 'others',
              label: 'Others',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Other button ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherButtonRef'],
          isHiding: (values, name) =>
            get(values, `${name}waistbandExtension`, '') === 'double_hook' ||
            get(values, `${name}buttonRefSuit`, '') === 'same_as_jacket' ||
            get(values, `${name}buttonRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        //
        {
          label: 'Lining pant',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['liningPant'],
          options: [
            {
              key: 'half_leg',
              label: 'Half leg (standard)',
            },
            {
              key: 'full_leg',
              label: 'Full leg',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Contrast color',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['contrastColor'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        //
        {
          label: 'Contrast color ref',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          key: ['contrastColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') !== 'yes',
          options: [
            { key: 'fe029023', label: 'Black silk (FE029023)' },
            { key: 'fe029025', label: 'Blue silk (FE029025)' },
            { key: 'fp010020', label: 'Red suede (FP010020)' },
            { key: 'fp015782', label: 'Dark blue suede (FP015782)' },
            { key: 'fp010043', label: 'Navy blue suede (FP010043)' },
            { key: 'fp011014', label: 'Brown suede (FP011014)' },
            { key: 'fp010114', label: 'Grey suede (FP010114)' },
            { key: 'fe029024', label: 'White silk (FE029024)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other Contrast color ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherContrastColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') !== 'yes' ||
            get(values, `${name}contrastColorRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Contrast position',
          type: 'multiSelect',
          key: ['contrastPositionMulti'],
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') !== 'yes',
          options: [
            // {
            //   key: 'no',
            //   label: 'No',
            // },
            {
              key: 'belt_loop',
              label: 'Belt loop',
            },
            {
              key: 'side_pocket',
              label: 'Side pocket',
            },
            {
              key: 'back_pocket',
              label: 'Back pocket',
            },
            {
              key: 'waistband',
              label: 'Waistband',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Comment',
          type: 'text',
          foldable: true,
          key: ['comment'],
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const PantMeasureForm = {
  title: 'Final measurement Pant',
  display: 'Pant',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Waist pant',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['waist'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Pant length',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['length'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Hip',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['hipGirth'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Whole crotch',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['wholeCrotch'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Thigh',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['thigh'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Knee',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['legWidth'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Calf',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['calf'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Pant cuff opening (Min 25 - Max 45)',
          type: 'number',
          isRequired: true,
          placeholder: '25-45',
          key: ['legOpening'],
          min: 25,
          max: 45,
          default: 38,
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        // {
        //   label: 'Cuff opening (cm) ',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['cuffOpening'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
      ],
    },
  ],
};
