import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Checkbox,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Tag,
  TimePicker,
  Typography,
  Upload,
  message,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { useStore } from '../../context/store';
import { SERVER } from '../../network/API';

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Title } = Typography;

// const upperCaseFirstChar = (str) =>
//     str
//         ? str?.charAt(0).toUpperCase() + str?.slice(1)
//         : (Math.random() + 1).toString(36).substring(7)

const FormField = (props) => {
  if (props.field.isConditional) {
    return (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          JSON.stringify(prevValues) !== JSON.stringify(currentValues)
        }
      >
        {({ getFieldValue }) => {
          let parentValue;

          if (props.field.staticParentKey) {
            parentValue = getFieldValue([
              ...props.blockKey,
              ...props.field.staticParentKey,
            ]);
          } else {
            parentValue = getFieldValue([
              ...props.prev,
              ...props.field.parentKey,
            ]);
          }

          return props.field.displayCondition(parentValue) ? (
            <FormFieldSub {...props} form={props.form} />
          ) : null;
        }}
      </Form.Item>
    );
  } else if (
    // isHiding function support
    typeof props.field.isHiding === 'function' &&
    props.field?.isHiding(
      props.form.getFieldValue(['details', ...props.field.key.slice(0, -1)]),
      ''
    )
  ) {
    return null;
  } else if (props.field.render) {
    return (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          JSON.stringify(prevValues) !== JSON.stringify(currentValues)
        }
      >
        {props.field.render}
      </Form.Item>
    );
  } else {
    return (
      <FieldWrapper
        {...props}
        form={props.form}
        foldable={props.field.foldable}
      />
    );
  }
};

function isSubArray(subArray, mainArray) {
  for (let i = 0; i <= mainArray.length - subArray.length; i++) {
    if (
      mainArray.slice(i, i + subArray.length).toString() === subArray.toString()
    ) {
      return true;
    }
  }
  return false;
}

const FieldWrapper = (props) => {
  // Get if there's error for current field
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  function updateActive() {
    const fieldError = props.form?.getFieldsError().some(({ name, errors }) => {
      return isSubArray(props.field.key, name) && errors.length > 0;
    });

    console.log(props, props.field.key, fieldError);
    setError(fieldError);
    fieldError && setOpen(true);
  }

  useEffect(() => {
    document.addEventListener('publish-form-failed', updateActive);
    return () =>
      document.removeEventListener('publish-form-failed', updateActive);
  }, []);

  return props.foldable ? (
    <Collapse
      className={`site-collapse-custom-collapse`}
      ghost
      bordered={false}
      activeKey={open ? ['1'] : []}
    >
      <Panel
        header={
          <span className={error ? 'error-text' : ''}>{props.field.label}</span>
        }
        key={'1'}
        className={`site-collapse-custom-panel hide-label`}
        forceRender
        onClick={() => setOpen(!open)}
      >
        <FormFieldSub {...props} form={props.form} />
      </Panel>
    </Collapse>
  ) : (
    <FormFieldSub {...props} form={props.form} />
  );
};

const FormFieldSub = ({
  field,
  dataRecieved,
  sectionKey,
  blockKey = [],
  name,
  fieldKey,
  form,
  labelNb,
  channel,
  ...restField
}) => {
  delete restField.foldable;

  const mp = useStore(state => state.mp);

  const [searchValue, setSearchValue] = useState('');
  const dynamicOptionConfig = [
    [
      // format naming to find request in react query
      !!field.keySearch
        ? field.keySearch
        : `get${field.key?.length ? field.key[0] : sectionKey}Select`,
      {
        category: field.category,
        filter: field.conditionalFilter ? field.conditionalFilter(form) : {},
      },
      form,
      field,
      restField,
      mp,
    ],
    field.requestOption,
    {
      refetchOnWindowFocus: false,
    },
  ];

  const dynamicSearchConfig = [
    [
      // format naming to find request in react query
      `get${field.key?.length ? field.key[0] : sectionKey}Search`,
      {
        [field.key]: searchValue,
      },
    ],
    field.requestSearch,
    {
      onSuccess: (data) => (data.length ? dataRecieved(data[0]) : null),
      refetchOnWindowFocus: false,
    },
  ];

  const requestProps =
    field.type === 'search'
      ? dynamicSearchConfig
      : field.requestOption !== undefined
      ? dynamicOptionConfig
      : ['', () => {}, { enabled: false }];

  const options = useQuery(...requestProps);
  const isSearchValid = options.data && options.data.length ? '' : 'error';

  const formProps = {
    name:
      name !== undefined
        ? [...blockKey, name, ...field.key]
        : [...blockKey, ...field.key],
    fieldKey:
      fieldKey !== undefined ? [fieldKey, ...field.key] : [...field.key],
    label: field.label
      ? field.label +
        (field.suffixLabel ? ` (${field.suffixLabel})` : '') +
        (labelNb ? labelNb : '')
      : null,
    rules: field.rules
      ? field.rules
      : [
          {
            required: field.isRequired,
            message: 'Field required',
            // type: field.ruleType,
          },
        ],
  };

  const match = useRouteMatch('/products');

  useEffect(() => {
    if (match) {
      // If the first key is number, then the element is under a list
      if (!!field.default && typeof field.key[0] === 'number') {
        // Some definition in template object are not valid for the field type, skip them
        if (
          field.type === 'basicRadio' &&
          !field.options.map((option) => option.key).includes(field.default)
        )
          return;

        // The array is under details field
        form.setFieldValue(['details', ...field.key], field.default);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (field.type) {
    // case "richtext":
    //   return (
    //     <Wysiwyg {...restField} {...formProps} form={form} field={field} />
    //   );
    case 'select':
      return (
        <Form.Item {...formProps}>
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={field.default}
            loading={options.isLoading}
            disabled={field.isInactive && typeof field.isInactive === 'function' ? field.isInactive(form) :field.isInactive}
            onChange={
              !!field.onChange ? () => field.onChange(name, form) : undefined
            }
          >
            {field.options?.map((option) => (
              <Option key={option.key} value={option.key}>
                {option.label}
              </Option>
            ))}
            {(!!field.optionParser
              ? field.optionParser(options.data?.list, name, form)
              : options.data?.list
            )?.map((option) => (
              <Option key={option.id} value={option.id}>
                {field.renderOption
                  ? field.renderOption(option)
                  : option.name?.en || option.name?.zh || option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    case 'basicRadio':
      return (
        <Form.Item {...restField} {...formProps}>
          <Radio.Group
            initialValues={field.default}
            defaultValue={field.default}
            disabled={field.isInactive}
            className="radio-group"
            optionType={field.radioType}
            buttonStyle="solid"
            options={[
              ...(field.options?.map((option) => ({
                label: option.label,
                value: option.key,
              })) || []),
              ...(options.data?.list?.map((option) => ({
                label: option.name?.en || option.name?.zh || option.name,
                value: option.id,
              })) || []),
            ]}
          >
            {/* {field.options?.map((option) => (
              <Radio key={option.key} value={option.key}>
                {option.label}
              </Radio>
            ))}
            {options.data?.list.map((option) => (
              <Radio key={option.id} value={option.id}>
                {option.name?.en || option.name?.zh || option.name}
              </Radio>
            ))} */}
          </Radio.Group>
        </Form.Item>
      );
    case 'radio':
      return (
        <Form.Item {...restField} {...formProps}>
          <Radio.Group
            initialValues={field.default}
            defaultValue={field.default}
            buttonStyle="solid"
            disabled={field.isInactive}
            className="radio-group"
          >
            {field.options?.map((option) => (
              <Radio.Button key={option.key} value={option.key}>
                {option.label}
              </Radio.Button>
            ))}
            {options.data?.list.map((option) => (
              <Radio.Button key={option.id} value={option.id}>
                {option.name?.en || option.name?.zh || option.name}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      );
    case 'upload':
    case 'multiupload':
      return (
        <UploadFile {...restField} {...formProps} form={form} field={field} />
      );
    case 'date':
    case 'day':
      return (
        <Form.Item {...restField} {...formProps}>
          <DatePicker
            disabled={field.isInactive}
            defaultValue={field.default}
            format={field.type === 'day' ? 'dddd DD' : 'YYYY-MM-DD'}
          />
        </Form.Item>
      );
    case 'month':
      return (
        <Form.Item {...restField} {...formProps}>
          <DatePicker
            disabled={field.isInactive}
            defaultValue={field.default}
            format={'MMMM'}
            picker="month"
          />
        </Form.Item>
      );
    case 'year':
      return (
        <Form.Item {...restField} {...formProps}>
          <DatePicker
            disabled={field.isInactive}
            defaultValue={field.default}
            format={'YYYY'}
            picker="year"
          />
        </Form.Item>
      );
    case 'time':
      return (
        <Form.Item {...restField} {...formProps}>
          <TimePicker disabled={field.isInactive} format={'HH:mma'} />
        </Form.Item>
      );
    case 'daterange':
      return (
        <Form.Item {...restField} {...formProps}>
          <RangePicker disabled={field.isInactive} />
        </Form.Item>
      );
    case 'multidate':
      return (
        <MultiDate
          itemProps={{
            ...restField,
            ...formProps,
          }}
          field={field}
          form={form}
        />
      );
    // case "area":
    //   return (
    //     <AreaLoader
    //       itemProps={{
    //         ...restField,
    //         ...formProps,
    //       }}
    //       field={field}
    //       form={form}
    //     />
    //   );
    case 'text':
      return (
        <Form.Item {...restField} {...formProps}>
          <TextArea
            rows={4}
            disabled={field.isInactive}
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </Form.Item>
      );
    case 'packList':
    case 'allPackList':
    case 'itemList':
      return (
        <PackList
          options={options}
          field={field}
          form={form}
          blockKey={blockKey}
          channel={channel}
          {...restField}
          {...formProps}
        />
      );
    case 'multicheck':
      return (
        <MultiCheck
          options={options}
          field={field}
          {...restField}
          {...formProps}
        />
      );
    case 'number':
      return (
        <Form.Item {...restField} {...formProps}>
          <InputNumber
            type="tel"
            min={field.min || 0}
            max={field.max}
            formatter={(e) => {
              let num;

              if (field.isInt) {
                num = parseInt(e);
              } else {
                num = parseFloat(e);
              }
              if (isNaN(num)) {
                return '';
              }
              return num;
            }}
            placeholder={field.placeholder}
            disabled={field.isInactive}
            defaultValue={field.default}
          />
        </Form.Item>
      );
    case 'password':
      return (
        <Form.Item {...restField} {...formProps}>
          <Input.Password
            disabled={field.isInactive}
            placeholder="••••••••••••••"
          />
        </Form.Item>
      );
    case 'search':
      return (
        <Form.Item
          validateStatus={options.isLoading ? 'validating' : isSearchValid}
          {...restField}
          {...formProps}
        >
          <Input
            prefix={field.pre}
            disabled={field.isInactive}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Form.Item>
      );
    case 'search&update':
      return (
        <SearchAndUpdate
          itemProps={{
            ...restField,
            ...formProps,
          }}
          field={field}
          form={form}
        />
      );
    case 'select&update':
      return (
        <SelectAndUpdate
          itemProps={{
            ...restField,
            ...formProps,
          }}
          field={field}
          form={form}
        />
      );
    case 'title':
      return <Title level={3}>{field.label}</Title>;
    case 'hidden':
      return <Form.Item {...restField} {...formProps} />;
    case 'multiSelect':
      return (
        <Select
          mode="multiple"
          name={name}
          style={{ width: '50%' }}
          form={form}
          field={field}
        >
          {field.options.map((e) => (
            <Select.Option key={e.key} value={e.key}>
              {e.label}
            </Select.Option>
          ))}
        </Select>
      );
    case 'multiSelectWithLabel':
      console.log(name, form, field);
      return (
        <Form.Item {...restField} {...formProps}>
          <Select mode="multiple" name={name} form={form} field={field}>
            {(field.options || []).map((e) => (
              <Select.Option key={e.key} value={e.key}>
                {e.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    case 'checkbox':
      return (
        <Form.Item {...restField} {...formProps} valuePropName={'checked'}>
          <Checkbox disabled={field.isInactive} />
        </Form.Item>
      );
    default:
      return (
        <Form.Item {...restField} {...formProps}>
          <Input
            prefix={field.pre}
            placeholder={field.placeholder}
            disabled={field.isInactive}
            defaultValue={field.default}
          />
        </Form.Item>
      );
  }
};

const MultiCheck = ({ field, options, ...restProps }) => {
  return (
    <Form.Item {...restProps}>
      <Checkbox.Group disabled={field.isInactive} className="check-group">
        <Space>
          {options.isLoading && <LoadingOutlined />}
          {field.options?.map((option) => (
            <Checkbox
              key={option.key}
              value={option.key}
              style={{ lineHeight: '32px' }}
            >
              {option.label}
            </Checkbox>
          ))}
          {options.data?.list?.map((option) => (
            <Checkbox
              key={option.id}
              value={option.id}
              style={{ lineHeight: '32px' }}
            >
              {option.name?.en || option.name?.zh || option.name}
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    </Form.Item>
  );
};

const MultiDate = ({ itemProps, form }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  function onChange(date, dateString) {
    if (selectedDate.findIndex((e) => e === dateString) > -1) {
      message.warning('Date already selected!');
      return;
    }
    const tempArray = [...selectedDate];
    tempArray.push(dateString);
    setSelectedDate(tempArray);
    form.setFieldsValue({ [itemProps.fieldKey]: tempArray });
  }

  function getValueFromEvent() {
    return selectedDate;
  }

  function handleClose(value) {
    const tempArray = [...selectedDate];
    const elemtentIndex = tempArray.findIndex((e) => e === value);
    if (elemtentIndex > -1) tempArray.splice(elemtentIndex, 1);
    setSelectedDate(tempArray);
    form.setFieldsValue({ [itemProps.fieldKey]: tempArray });
  }

  return (
    <>
      <DatePicker onChange={onChange} value={null} />
      <Form.Item
        {...itemProps}
        getValueFromEvent={getValueFromEvent}
        getValueProps={(e) => {
          if (!selectedDate) {
            if (e) {
              let tempDate = e.map((date) => moment(date).format('YYYY-MM-DD'));
              setSelectedDate(tempDate);
              form.setFieldsValue({
                [itemProps.fieldKey]: tempDate,
              });
            } else {
              setSelectedDate([]);
            }
          }
        }}
        shouldUpdate={(prevValues, currentValues) =>
          JSON.stringify(prevValues) !== JSON.stringify(currentValues)
        }
      >
        <Space direction="vertical">
          <div className="multi-date-tags-wrap">
            {selectedDate?.map((date, key) => (
              <div className="multi-date-tags-item">
                <Tag
                  key={key}
                  color="blue"
                  closable
                  onClose={(e) => {
                    e.preventDefault();
                    handleClose(date);
                  }}
                >
                  {date}
                </Tag>
              </div>
            ))}
          </div>
          {/* <p>{selectedDate.join()}</p> */}
        </Space>
      </Form.Item>
    </>
  );
};

const SearchAndUpdate = ({ itemProps, field, form }) => {
  const [text, setText] = useState('');

  const results = useQuery(
    [
      `getFrom${field.key}`,
      {
        filter: { [field.searchKey]: { $regex: text, $options: 'i' } },
        range: [0, 4],
        sort: ['name', 'ASC'],
      },
    ],
    field.options,
    {
      onSuccess: (res) => {
        if (
          res.list.length === 1 &&
          res.list[0][field.searchKey].toLowerCase() === text.toLowerCase()
        ) {
          form.setFieldsValue(field.formatOnRecieved(res.list[0]));
        }
      },
      enabled: text !== '',
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Form.Item {...itemProps} hasFeedback validateStatus={results.isLoading}>
      <AutoComplete
        onChange={(e) => {
          setText(e);
          if (field.OnType) {
            form.setFieldsValue(field.OnType);
          }
        }}
        onSelect={(e) => {
          const value = JSON.parse(e);
          setText(value[field.searchKey]);
          form.setFieldsValue(field.formatOnRecieved(value));
        }}
      >
        {results?.data?.list?.map((res) => (
          <Option key={res.id} value={JSON.stringify(res)}>
            {res[field.searchKey]}
          </Option>
        ))}
      </AutoComplete>
    </Form.Item>
  );
};

const SelectAndUpdate = ({ itemProps, field, form }) => {
  const options = useQuery([`get${field.key.join('')}`], field.options);

  const mutation = useMutation(
    (value) =>
      field.update({
        queryKey: [null, { [field.updateKey]: value }],
      }),
    {
      onSuccess: (data) => {
        // form.setFieldsValue({
        //     [field.dataKeys.to]: field.formatOnRecieved(
        //         data[field.dataKeys.from]
        //     ),
        // })
        form.setFieldsValue(field.formatOnRecieved(data));
      },
      onError: () => message.error('An error occurs'),
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Form.Item {...itemProps}>
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        loading={options.isLoading || mutation.isLoading}
        disabled={field.isInactive}
        onSelect={(e) => mutation.mutate(e)}
      >
        {options.data?.list.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name?.en || option.name?.zh || option.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

// const Wysiwyg = ({ field, form, ...restField }) => {
//     const value = BraftEditor.createEditorState(form?.getFieldValue(field.key))
//     const getEditorContent = (e) => {
//         return e.toHTML()
//     }

//     const controls = [
//         'bold',
//         'italic',
//         'separator',
//         'link',
//         'separator',
//         'list-ul',
//         'list-ol',
//         'separator',
//         'blockquote',
//         'media',
//         'separator',
//         'headings',
//         'separator',
//         'code',
//     ]

//     return (
//         <Form.Item getValueFromEvent={getEditorContent} {...restField}>
//             <BraftEditor
//                 defaultValue={value}
//                 controls={controls}
//                 language={i18next.language}
//                 media={{
//                     uploadFn: (params) => myUploadFn(params, token.token),
//                 }}
//             />
//         </Form.Item>
//     )
// }

const UploadFile = ({ field, form, token, ...restField }) => {
  const uploadProps = {
    name: 'file',
    action: `${SERVER.baseURL}${field.requestURI}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  const getFileList = () => {
    let savedPicture = form?.getFieldValue(field.key);

    savedPicture = savedPicture?.fileList
      ? savedPicture.fileList
      : savedPicture;

    if (savedPicture) {
      if (!Array.isArray(savedPicture)) {
        savedPicture = [savedPicture];
      }
      return savedPicture.map((pic, i) => ({
        uid: '-' + (i + 1),
        name: pic.name,
        status: 'done',
        thumbUrl: `${SERVER.baseURL}${field.storageURI}/${pic.uri}`,
        response: pic,
      }));
    }

    return [];
  };

  return (
    <Form.Item
      {...restField}
      valuePropName={field.key}
      // getValueFromEvent={normFile}
    >
      <Upload
        {...uploadProps}
        showUploadList={{
          showPreviewIcon: false,
        }}
        multiple={field.type === 'multiupload'}
        maxCount={field.type === 'multiupload' ? 12 : 1}
        listType="picture-card"
        disabled={field.isInactive}
        defaultFileList={getFileList()}
      >
        <div>
          <UploadOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
        {/* <Button icon={<UploadOutlined />}>{t('CREATE_OFFER_UPLOAD')}</Button> */}
      </Upload>
    </Form.Item>
  );
};

// const AreaLoader = ({ itemProps, field, form }) => {
//   const [options, setOptions] = useState([]);

//   const areas = useQuery(
//     ["getDeliveryAreas", { token: token.token }],
//     getDeliveryAreas,
//     {
//       onSuccess: (data) => {
//         setOptions(getAreas(undefined, data.list));
//       },
//     }
//   );

//   const displayRender = (labels, selectedOptions) =>
//     labels.length && options.length ? (
//       <span>{labels[labels.length - 1]}</span>
//     ) : null;

//   const getAreas = (parentId, allAreas) => {
//     let newAreas = [];
//     const filteredAreas = allAreas.filter((e) => e.parent === parentId);

//     if (filteredAreas.length) {
//       for (const line of filteredAreas) {
//         let childs = getAreas(line.id, allAreas);

//         newAreas.push({
//           value: line.id,
//           label: line.name,
//           children: childs.length ? childs : undefined,
//         });
//       }
//     }

//     return newAreas;
//   };

//   return (
//     <Form.Item {...itemProps}>
//       <Cascader
//         options={options}
//         displayRender={displayRender}
//         changeOnSelect
//       />
//     </Form.Item>
//   );
// };

const PackList = ({ field, options, form, channel, ...restProps }) => {
  const packs = form.getFieldValue([...channel, 'packs']);
  const items = form.getFieldValue([...channel, 'items']);

  if (field.type === 'packList') {
    return (
      <Form.Item
        {...restProps}
        shouldUpdate={(prevValues, currentValues) =>
          JSON.stringify(prevValues) !== JSON.stringify(currentValues)
        }
      >
        <Select disabled={field.isInactive}>
          {packs?.map((pack, i) => {
            const newShort = (i + 10).toString(36).toUpperCase();

            return (
              <Option key={newShort} value={newShort}>
                {newShort}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }

  if (field.type === 'allPackList') {
    return (
      <Form.Item
        {...restProps}
        shouldUpdate={(prevValues, currentValues) =>
          JSON.stringify(prevValues) !== JSON.stringify(currentValues)
        }
      >
        <Select disabled={field.isInactive}>
          {packs?.map((pack, i) => {
            const newShort = (i + 10).toString(36).toUpperCase();

            return (
              <Option key={newShort} value={newShort}>
                {newShort}
              </Option>
            );
          })}
          {items?.map((item, i) => {
            const newShort = (i + 10 + (packs?.length || 0))
              .toString(36)
              .toUpperCase();

            return (
              <Option key={newShort} value={newShort}>
                {newShort}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }

  return (
    <Form.Item
      {...restProps}
      shouldUpdate={(prevValues, currentValues) =>
        JSON.stringify(prevValues) !== JSON.stringify(currentValues)
      }
    >
      <Select disabled={field.isInactive}>
        {items?.map((item, i) => {
          const newShort = (i + 10 + (packs?.length || 0))
            .toString(36)
            .toUpperCase();

          return (
            <Option key={newShort} value={newShort}>
              {newShort}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default FormField;
