import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import get from 'lodash.get';
import set from 'lodash.set';
import { Button, Card, message, PageHeader, Space, Typography } from 'antd';
import { FieldArray, Form, Formik } from 'formik';
import { getClientDetails, updateClient } from '../../network/Clients';
import { createOrder as createOrderRequest } from '../../network/Orders';
import FormContent from './FormContent';
import { Payment } from '../../forms/blocks';
import { adjustmentInfos, calcPrice } from '../../forms/blocks/Adjustment';

import Loader from '../../components/Loader';
import Error from '../../components/Error';

const { Text, Title } = Typography;

const CreateOrder = ({ history }) => {
  let { userId } = useParams();
  const myRef = useRef(null);
  const [step, setStep] = useState(1);

  const clientDetails = useQuery(
    [
      'getClientDetails',
      {
        id: userId,
      },
    ],
    getClientDetails,
    {
      refetchOnWindowFocus: false,
    }
  );

  const createOrder = useMutation(createOrderRequest, {
    onSuccess: () => history.push('/orders'),
    onError: (error) => {
      message
        .error(
          error.response?.data?.debugLog?.message ||
            error.response?.data?.message ||
            'An error occurs'
        )
        .then((r) => console.log(r));
    },
  });

  const saveUser = useMutation((val) => updateClient(val, userId), {});

  const handleBack = () => {
    if (step === 1) {
      history.push('/adjustments/create/select');
    } else {
      myRef.current?.scrollIntoView();
      setStep(step - 1);
    }
  };

  const handleNext = () => {
    if (step === 5) {
      history.push('/orders');
    } else {
      myRef.current?.scrollIntoView();
      setStep(step + 1);
    }
  };

  const saveUserInfos = async (values) => {
    let clientInfos = get(clientDetails, 'data', {});

    const newInfos = {
      ...clientInfos,
      complexion: {
        ...get(clientInfos, 'complexion', {}),
        ...get(values, 'complexion', {}),
      },
      bodyPictures: get(values, 'bodyPictures', []),
      bodyMeasurements: {
        ...get(clientInfos, 'bodyMeasurements', {}),
        ...get(values, 'bodyMeasures', {}),
      },
      foot: { ...get(clientInfos, 'foot', {}), ...get(values, 'foot', {}) },
    };

    saveUser.mutate(newInfos);
  };

  return (
    <div className="" ref={myRef}>
      <PageHeader
        className="site-page-header"
        title="Create Adjustment"
        onBack={() => history.push('/adjustments/create/select')}
        style={{ backgroundColor: '#fff' }}
      />
      {clientDetails.isError ? (
        <Error retry={() => clientDetails.refetch()} />
      ) : clientDetails.isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{
            channel: 'store',
            customer: userId,
            address: get(clientDetails, 'data.addresses.0', {}),
            // personaeType: 'special_event',
            // bodyMeasures: get(clientDetails, 'data.bodyMeasurements', {}),
            // bodyPictures: get(clientDetails, 'data.bodyPictures', []),
            // complexion: {
            //   ...DefaultComplexion,
            //   ...get(clientDetails, 'data.complexion', DefaultComplexion),
            // },
            // foot: { ...get(clientDetails, 'data.foot', {}) },
            orderProducts: [
              {
                orderProductType: 'adjustment',
                orderAdjustment: {
                  itemDescritpion: 'af',
                  fittingNumber: '1',
                  // adjustment: 'smaller',
                  adjustmentPositions: [],
                },
              },
            ],
            discount: 0,
            validated: false,
          }}
          validate={(values) => {
            const errors = {};
            if (step === 1) {
              for (
                let i = 0;
                i < get(values, `orderProducts`, []).length;
                i++
              ) {
                const {
                  adjustmentPositions,
                  colors,
                  type,
                  fittingNumber,
                  itemDescritpion,
                  pictures,
                  orderNumber,
                } = get(values, `orderProducts.${i}.orderAdjustment`, []);

                console.log(values)

                // colors: "d"
                // fittingNumber: "1"
                // itemDescritpion: "other"
                // orderNumber: "d"

                if (!colors){
                  set(
                    errors,
                    `orderProducts.${i}.orderAdjustment.colors`,
                    'Required'
                  );
                }

                if (!fittingNumber)
                  set(
                    errors,
                    `orderProducts.${i}.orderAdjustment.fittingNumber`,
                    'Required'
                  );
                if (type !== 'shoes' && (!pictures || !Array.isArray(pictures) || pictures.length === 0))
                  set(
                    errors,
                    `orderProducts.${i}.orderAdjustment.pictures`,
                    'Required'
                  );

                if (!itemDescritpion)
                  set(
                    errors,
                    `orderProducts.${i}.orderAdjustment.itemDescritpion`,
                    'Required'
                  );

                if (!orderNumber && itemDescritpion === 'af')
                  set(
                    errors,
                    `orderProducts.${i}.orderAdjustment.orderNumber`,
                    'Required'
                  );

                for (let j = 0; j < adjustmentPositions?.length; j++) {
                  const {
                    positionRef,
                    adjustmentType,
                    color,
                    fix,
                    adjustment,
                    pictures,
                    cm,
                    finalMeasurment,
                    comment
                  } = adjustmentPositions[j];

                  if (!positionRef) {
                    set(
                      errors,
                      `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}.positionRef`,
                      'Required'
                    );
                  }
                  
                  // validation for shoes only
                  if (type === 'shoes') {
                    if (!adjustmentType) {
                      set(
                        errors,
                        `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}.adjustmentType`,
                        'Required'
                      );
                    }

                    if (adjustmentType === 'color' && !color) {
                      set(
                        errors,
                        `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}.color`,
                        'Required'
                      );
                    }

                    if (adjustmentType === 'size' && !adjustment) {
                      set(
                        errors,
                        `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}.adjustment`,
                        'Required'
                      );
                    }

                    if (adjustmentType === 'fix' && !fix) {
                      set(
                        errors,
                        `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}.fix`,
                        'Required'
                      );
                    }
                  }else{
                    // if (!comment) {
                    //   set(
                    //     errors,
                    //     `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}.comment`,
                    //     'Required'
                    //   );
                    // }
                    // if (!pictures || !Array.isArray(pictures) || pictures.length === 0) {
                    //   set(
                    //     errors,
                    //     `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}.pictures`,
                    //     'Required'
                    //   );
                    // }
                    if (!adjustment) {
                      set(
                        errors,
                        `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}.adjustment`,
                        'Required'
                      );
                    }
                    if(adjustment !== 'fix'){
                      if (!cm) {
                        set(
                          errors,
                          `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}.cm`,
                          'Required'
                        );
                      }
                      // if (!finalMeasurment) {
                      //   set(
                      //     errors,
                      //     `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}.finalMeasurment`,
                      //     'Required'
                      //   );
                      // }
                    }
                  }
                }
              }
            }
            return errors;
          }}
          onSubmit={async (values) => {
            let paymentStatus = 'unpaid';
            if (values.balance === 0) {
              paymentStatus = 'paid';
            } else if (values.deposit || values.deposit2) {
              paymentStatus = 'deposit';
            }
            // const orderProducts = values.orderProducts.map((prod) =>
            //   prod.orderProductType === 'collection'
            //     ? {
            //         ...prod,
            //         orderCollection: {
            //           ...prod.orderCollection,
            //           options: prod.orderCollection.options.map((option) => ({
            //             ...option,
            //             subOptions: option.subOptions.map((sub) => ({
            //               ...sub,
            //               selected: prod.orderCollection.optionsTemp.includes(
            //                 sub._id
            //               ),
            //             })),
            //           })),
            //         },
            //       }
            //     : prod
            // )
            saveUserInfos({
              detailedAddress: get(values, 'detailedAddress', ''),
            });
            createOrder.mutate({ ...values, paymentStatus });
          }}
        >
          {({ values, errors, handleChange, validateForm }) => (
            <Form style={{ padding: 24 }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {step === 1 && (
                  <Step1
                    values={values}
                    errors={errors}
                    validateForm={validateForm}
                    handleChange={handleChange}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                )}
                {step === 2 && (
                  <>
                    <Step5
                      values={values}
                      errors={errors}
                      // validateForm={validateForm}
                      handleChange={handleChange}
                    />
                    <Space>
                      <Button
                        disabled={createOrder.isLoading}
                        onClick={handleBack}
                        type="secondary"
                      >
                        Previous
                      </Button>

                      <Button
                        loading={createOrder.isLoading}
                        disabled={Object.keys(errors).length}
                        // onClick={handleNext}
                        type="primary"
                        htmlType="submit"
                      >
                        Finish order
                      </Button>
                    </Space>
                  </>
                )}
              </Space>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default CreateOrder;

const Step1 = ({
  values,
  errors,
  handleChange,
  handleBack,
  handleNext,
  validateForm,
}) => {
  const [isValidated, setIsValidated] = useState(false);

  const scrollToError = (key) => {
    const selector = `[name="${key}"]`;
    const errorElement = document.querySelector(selector);
    if(errorElement){
      errorElement.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }
  const goNextPage = () => {
    // for (const product of get(values, `orderProducts`, [])) {
    //   if (product.orderAdjustment.itemDescritpion === 'af') {
    //     handleChange({ target: { name: '', value: '' } })
    //   }
    // }

    for (let i = 0; i < get(values, `orderProducts`, []).length; i++) {
      if (
        get(
          values,
          `orderProducts.${i}.orderAdjustment.itemDescritpion`,
          null
        ) === 'af'
      ) {
        handleChange({
          target: {
            name: `orderProducts.${i}.orderAdjustment.price`,
            value: 0,
          },
        });
      } else {
        let price = 0;
        const type = get(values, `orderProducts.${i}.orderAdjustment.type`, '');
        const desc = get(
          values,
          `orderProducts.${i}.orderAdjustment.itemDescritpion`,
          ''
        );

        for (
          let j = 0;
          j <
          get(
            values,
            `orderProducts.${i}.orderAdjustment.adjustmentPositions`,
            []
          ).length;
          j++
        ) {
          const adjType = get(
            values,
            `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}.positionRef`,
            ''
          );

          let shoesCalculate = {};
          if (type === 'shoes') {
            shoesCalculate = get(
              values,
              `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}`,
              {}
            );
          }
          console.log(shoesCalculate)

          if (desc !== 'af') {
            price = price + calcPrice(type, adjType, shoesCalculate);
          }
        }

        handleChange({
          target: {
            name: `orderProducts.${i}.orderAdjustment.price`,
            value: price,
          },
        });
      }
    }

    handleNext();
  };

  return (
    <>
      <FieldArray name="orderProducts">
        {({ remove, push }) => (
          <Space direction="vertical" style={{ width: '100%' }}>
            {values.orderProducts.length > 0 &&
              values.orderProducts.map((friend, index) => (
                <>
                  <Space>
                    <Title level={3}>Product to Adjust {index + 1}</Title>

                    <Button onClick={() => remove(index)} type="link">
                      Delete
                    </Button>
                  </Space>

                  <div
                    className='create-adjustment-categories'
                    // style={{
                    //   display: 'grid',
                    //   gridTemplateColumns: 'repeat(6, 1fr)',
                    //   width: 'fit-content',
                    //   columnGap: 12,
                    //   rowGap: 12,
                    // }}
                  >
                    {Object.keys(adjustmentInfos).map((key) => (
                      <div
                        onClick={() =>
                          handleChange({
                            target: {
                              name: `orderProducts.${index}`,
                              value: {
                                orderProductType: 'adjustment',
                                orderAdjustment: {
                                  type: key,
                                  itemDescritpion: 'af',
                                  fittingNumber: '1',
                                  orderNumber: get(
                                    values,
                                    'orderProducts.0.orderAdjustment.orderNumber',
                                    ''
                                  ),
                                  adjustment:
                                    key === 'shoes' ? undefined : 'smaller',
                                  adjustmentPositions: [],
                                },
                              },
                            },
                          })
                        }
                        className={`order-btn big${
                          get(
                            values,
                            `orderProducts.${index}.orderAdjustment.type`,
                            null
                          ) === key
                            ? ' selected'
                            : ''
                        }`}
                      >
                        <Text type="secondary" strong>
                          {adjustmentInfos[key].name}
                        </Text>
                      </div>
                    ))}
                  </div>
                  <FormContent
                    values={values}
                    errors={errors}
                    hideErrors={!isValidated}
                    path={`orderProducts.${index}`}
                    handleChange={handleChange}
                    forms={
                      adjustmentInfos[
                        get(
                          values,
                          `orderProducts.${index}.orderAdjustment.type`,
                          null
                        )
                      ]?.forms
                    }
                  />
                </>
              ))}

            <Space>
              <Button onClick={handleBack} type="secondary">
                Previous
              </Button>
              <Button
                type="button"
                className="secondary"
                onClick={() => {
                  push({});
                }}
                // danger={errors.orderProducts ? true : false}
              >
                Add Product to Adjust
              </Button>
              <Button
                // disabled={Object.keys(errors).length}
                onClick={() => {
                  validateForm().then((res) =>{
                    function ErrorBack(value, currentKey){
                      if(typeof value === 'string' || !value){
                        return currentKey
                      }else{
                        const temp = Object.keys(value)[0];
                        if(temp){ return ErrorBack(value[temp], currentKey ? currentKey + '.' + temp : temp)}
                      }
                    }
                    const key = ErrorBack(res)
                    if(key){
                      scrollToError(key)
                    }
                    Object.keys(res).length
                      ? setIsValidated(true)
                      : goNextPage()}
                  );
                }}
                type="primary"
              >
                Next
              </Button>
            </Space>
          </Space>
        )}
      </FieldArray>
    </>
  );
};

const Step5 = ({ values, errors, handleChange }) => {
  const formsPayment = [{ form: Payment, keys: '' }];

  useEffect(() => {
    let total = 0;

    for (const prod of values.orderProducts) {
      switch (prod.orderProductType) {
        case 'collection':
          total += get(prod, 'orderCollection.price', 0);
          break;
        case 'custom':
          total += get(prod, 'orderCustom.price', 0);
          break;

        case 'adjustment':
          total += get(prod, 'orderAdjustment.price', 0);
          break;

        default:
          break;
      }
    }
    total = parseFloat(total.toFixed(2));

    handleChange({
      target: {
        name: 'totalSpent',
        value: total,
      },
    });
    handleChange({
      target: {
        name: 'balance',
        value:
          total -
          get(values, 'discount', 0) -
          get(values, 'deposit', 0) -
          get(values, 'deposit2', 0),
        // (values.discount || 0) -
        // (values.deposit || 0) -
        // (values.deposit2 || 0)
      },
    });
  }, [handleChange, values]);

  useEffect(() => {
    let val = get(values, 'totalSpent', 0);
    val = parseFloat((val - get(values, 'discount', 0)).toFixed(2));
    val = parseFloat(
      (val - get(values, 'deposit', 0) - get(values, 'deposit2', 0)).toFixed(2)
    );

    handleChange({
      target: {
        name: 'balance',
        value: val,
      },
    });
  }, [values.totalSpent, values.discount, values.deposit, values.deposit2, handleChange, values]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card title="Total Summary">
        {values.orderProducts.map((prod, index) => {
          return (
            <p key={index}>
              <strong>{`x${
                get(prod, 'orderAdjustment.adjustmentPositions', []).length
              } Adjustment:`}</strong>
              {` CNY ${prod.orderAdjustment.price}`}
            </p>
          );
        })}
      </Card>
      <FormContent
        values={values}
        errors={errors}
        path=""
        handleChange={handleChange}
        forms={formsPayment}
      />
    </Space>
  );
};
