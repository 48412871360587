import get from 'lodash.get';

export const BeltDetailsForm = {
  title: 'Belt details',
  display: 'Belt',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Size in cm',
          type: 'basicRadio',
          isRequired: true,
          key: ['size'],
          options: [
            { key: '85', label: '85' },
            { key: '90', label: '90' },
            { key: '95', label: '95' },
            { key: '100', label: '100' },
            { key: '105', label: '105' },
            { key: '110', label: '110' },
            { key: '115', label: '115' },
            { key: '120', label: '120' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            // row: 2,
            size: 2,
          },
        },
        {
          label: 'Input size',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['otherSize'],
          isHiding: (values, name) =>
            get(values, `${name}size`, '') !== 'others',
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Material',
          type: 'basicRadio',
          isRequired: true,
          key: ['material'],
          options: [
            { key: 'leather', label: 'Leather' },
            { key: 'suede', label: 'Suede' },
          ],
          pos: {
            col: 1,
            // row: 2,
            size: 2,
          },
        },
        {
          label: 'Leather color ref',
          type: 'select',
          isRequired: true,
          key: ['leatherColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}material`, '') !== 'leather',
          options: [
            { key: 'l1', label: 'L1 - Blue' },
            { key: 'l2', label: 'L2 - Patina black' },
            { key: 'l3', label: 'L3 - Yellowish brown' },
            { key: 'l4', label: 'L4 - Wine' },
            { key: 'l5', label: 'L5 - Deep green' },
            { key: 'l6', label: 'L6 - Deep brown' },
            { key: 'l7', label: 'L7 - Brownish grey' },
            { key: 'l8', label: 'L8 - Blackish grey' },
            { key: 'l9', label: 'L9 - Solid black' },
          ],
          pos: {
            col: 1,
            // row: 2,
            size: 2,
          },
        },
        {
          label: 'Suede color ref',
          type: 'select',
          isRequired: true,
          key: ['suedeColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}material`, '') !== 'suede',
          options: [
            { key: 's1', label: 'S1 - Black' },
            { key: 's2', label: 'S2 - Navy blue' },
            { key: 's3', label: 'S3 - Dark blue' },
            { key: 's4', label: 'S4 - Camel' },
            { key: 's5', label: 'S5 - Wine' },
            { key: 's6', label: 'S6 - Dark brown' },
            { key: 's7', label: 'S7 - Olive' },
            { key: 's8', label: 'S8 - Green' },
            { key: 's9', label: 'S9 - lighter brown' },
          ],
          pos: {
            col: 1,
            // row: 2,
            size: 2,
          },
        },
        {
          label: 'Buckle',
          type: 'basicRadio',
          isRequired: true,
          key: ['buckle'],
          options: [
            {
              key: 'logo',
              label: 'Logo buckle',
            },
            {
              key: 'niddle',
              label: ' Niddle buckle',
            },
          ],
          pos: {
            col: 1,
            // row: 2,
            size: 2,
          },
        },
        {
          label: 'Buckle color',
          type: 'basicRadio',
          isRequired: true,
          key: ['buckleColor'],
          options: [
            {
              key: 'black',
              label: 'Black',
            },
            {
              key: 'silver',
              label: 'Silver',
            },
            {
              key: 'gold',
              label: 'Gold',
            },
          ],
          pos: {
            col: 1,
            // row: 2,
            size: 2,
          },
        },

        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const BeltMeasureForm = {
  title: 'Final measurement Belt',
  display: 'Belt',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [],
    },
  ],
};
