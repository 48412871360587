import get from 'lodash.get';

export const VestDetailsForm = {
  title: 'Vest details',
  display: 'Vest',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        // {
        //   label: 'Price',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['price'],
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        // {
        //   label: 'Fabric ref',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['fabricRef'],
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Front fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['fabricRef'],
          pos: {
            col: 1,
            size: 1,
          }
        },
        {
          label: 'Back fabric',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['backFabric'],
          options: [
            {
              key: 'same_front_fabric',
              label: 'Same as front fabric',
            },
            {
              key: 'same_lining',
              label: 'Same as lining',
            },
            {
              key: 'other',
              label: 'Others',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Back fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['backFabricRef'],
          isHiding: (values, name) =>
            get(values, `${name}backFabric`, '') !== 'other',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Lining ref',
          isRequired: true,
          foldable: true,
          placeholder: '-',
          key: ['liningRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },

        //

        {
          label: 'Button ref',
          type: 'select',
          isRequired: true,
          foldable: true,
          key: ['buttonRef'],
          options: [
            { key: 'fk503202', label: 'Classic black horn (FK503202)' },
            { key: 'fkE03237', label: 'Tinted horn blue (FKE03237)' },
            { key: 'fkE03236', label: 'Tinted horn red (FKE03236)' },
            { key: 'fk743204', label: 'Brown shell (FK743204)' },
            { key: 'fk523212', label: 'Grey shell (FK523212)' },
            { key: 'fk213204', label: 'Silk black (FK213204)' },
            { key: 'fk743203', label: 'White shell (FK743203)' },
            { key: 'fk523205', label: 'Brown horn (FK523205)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Other Button ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherButtonRef'],
          isHiding: (values, name) =>
            get(values, `${name}buttonRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Shape',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['shape'],
          options: [
            { key: 'u_shape', label: 'U shape' },
            { key: 'v_shape', label: 'V shape' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Vest style',
          type: 'select',
          key: ['vestStyle'],
          options: [
            {
              key: 'single_breasted_3_buttons',
              label: 'Single breasted (3 buttons)',
            },
            {
              key: 'single_breasted_4_buttons',
              label: 'Single breasted (4 buttons)',
            },
            {
              key: 'single_breasted_5_buttons',
              label: 'Single breasted (5 buttons)',
            },
            {
              key: 'single_breasted_7_buttons',
              label: 'Single breasted (7 buttons)',
            },
            {
              key: 'double_breasted_4_buttons',
              label: 'Double breasted (4 buttons)',
            },
            {
              key: 'double_breasted_6_buttons',
              label: 'Double breasted (6 buttons)',
            },
            {
              key: 'double_breasted_8_buttons',
              label: 'Double breasted (8 buttons)',
            },
            {
              key: 'others',
              label: 'Others',
            },
          ],
          isRequired: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other Vest style',
          isRequired: true,
          placeholder: '-',
          key: ['otherVestStyle'],
          isHiding: (values, name) =>
            get(values, `${name}vestStyle`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Vest lapel style',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['vestLapelStyle'],
          default: 'no_lapel',
          options: [
            {
              key: 'notch_lapel',
              label: 'Notch lapel',
            },
            {
              key: 'peak_lapel',
              label: 'Peak lapel',
            },
            {
              key: 'shawl_lapel',
              label: 'Shawl lapel',
            },
            {
              key: 'no_lapel',
              label: 'No lapel',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Vest chest pocket',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          default: 'none',
          key: ['vestChestPocket'],
          options: [
            {
              key: 'none',
              label: 'No chest',
            },
            {
              key: 'left_single',
              label: 'Left single',
            },
            {
              key: 'left_double',
              label: 'Left double',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Vest front pocket',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['vestFrontPocket'],
          options: [
            {
              key: '2cm_width',
              label: '2 cm width',
            },
            {
              key: 'double_bessom',
              label: 'Double bessom',
            },
            {
              key: 'no_pocket',
              label: 'No pocket',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Vest bottom shape',
          type: 'basicRadio',
          isRequired: true,
          key: ['vestBottomShape'],
          options: [
            {
              key: 'cross_arrow',
              label: 'Cross arrow',
            },
            {
              key: 'straight_bottom',
              label: 'Straight bottom',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Contrast fabric',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['contrastFabric'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        //
        {
          label: 'Contrast fabric ref',
          type: 'select',
          isRequired: true,
          key: ['contrastFabricRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastFabric`, '') === 'no',
          options: [
            { key: 'fe029023', label: 'Black silk (FE029023)' },
            { key: 'fe029025', label: 'Blue silk (FE029025)' },
            { key: 'fp010020', label: 'Red suede (FP010020)' },
            { key: 'fp015782', label: 'Dark blue suede (FP015782)' },
            { key: 'fp010043', label: 'Navy blue suede (FP010043)' },
            { key: 'fp011014', label: 'Brown suede (FP011014)' },
            { key: 'fp010114', label: 'Grey suede (FP010114)' },
            { key: 'fe029024', label: 'White silk (FE029024)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Other contrast fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherContrastFabricRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastFabric`, '') === 'no' ||
            get(values, `${name}contrastFabricRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Contrast position',
          type: 'multiSelect',
          key: ['contrastPositionMulti'],
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}contrastFabric`, '') === 'no',
          options: [
            {
              key: 'chest_pocket',
              label: 'Chest pocket',
            },
            {
              key: 'front_pocket',
              label: 'Front pocket',
            },
            {
              key: 'upper_collar',
              label: 'Upper collar',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },

        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          foldable: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const VestSuitDetailsForm = {
  title: 'Vest details',
  display: 'Vest',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        // {
        //   label: 'Price',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['price'],
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Front fabric',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['fabric'],
          options: [
            {
              key: 'same_front_fabric',
              label: 'Same as jacket fabric ',
            },
            {
              key: 'same_lining',
              label: 'Same as lining',
            },
            {
              key: 'other',
              label: 'Others',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        // {
        //   label: 'Fabric ref type',
        //   type: 'basicRadio',
        //   isRequired: true,
        //   key: ['fabricRefSuit'],
        //   options: [
        //     {
        //       key: 'same_as_jacket',
        //       label: 'Same as jacket',
        //     },
        //     {
        //       key: 'other',
        //       label: 'Others',
        //     },
        //   ],
        //   pos: {
        //     col: 1,
        //     size: 2,
        //   },
        // },
        {
          label: 'Fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['fabricRef'],
          isHiding: (values, name) =>
            get(values, `${name}fabric`, '') !== 'other',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Back fabric',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['backFabric'],
          options: [
            {
              key: 'same_front_fabric',
              label: 'Same as front fabric',
            },
            {
              key: 'same_lining',
              label: 'Same as lining',
            },
            {
              key: 'other',
              label: 'Others',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Back fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['backFabricRef'],
          isHiding: (values, name) =>
            get(values, `${name}backFabric`, '') !== 'other',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Lining',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['lining'],
          options: [
            {
              key: 'same_suit_lining',
              label: 'Same as jacket lining',
            },
            {
              key: 'other',
              label: 'Others',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Lining ref',
          isRequired: true,
          placeholder: '-',
          key: ['liningRef'],
          isHiding: (values, name) =>
            get(values, `${name}lining`, '') !== 'other',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Button',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['button'],
          options: [
            {
              key: 'same_as_suit',
              label: 'Same as jacket button',
            },
            {
              key: 'other',
              label: 'Others',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        // {
        //   label: 'Fabric ref type',
        //   type: 'basicRadio',
        //   isRequired: true,
        //   key: ['fabricRefSuit'],
        //   options: [
        //     {
        //       key: 'same_as_jacket',
        //       label: 'Same as jacket',
        //     },
        //     {
        //       key: 'other',
        //       label: 'Others',
        //     },
        //   ],
        //   pos: {
        //     col: 1,
        //     size: 2,
        //   },
        // },
        {
          label: 'Button ref',
          type: 'select',
          isRequired: true,
          key: ['buttonRef'],
          isHiding: (values, name) =>
            get(values, `${name}button`, '') !== 'other',
          options: [
            { key: 'fk503202', label: 'Classic black horn (FK503202)' },
            { key: 'fkE03237', label: 'Tinted horn blue (FKE03237)' },
            { key: 'fkE03236', label: 'Tinted horn red (FKE03236)' },
            { key: 'fk743204', label: 'Brown shell (FK743204)' },
            { key: 'fk523212', label: 'Grey shell (FK523212)' },
            { key: 'fk213204', label: 'Silk black (FK213204)' },
            { key: 'fk743203', label: 'White shell (FK743203)' },
            { key: 'fk523205', label: 'Brown horn (FK523205)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Other Button ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherButtonRef'],
          isHiding: (values, name) =>
            get(values, `${name}button`, '') !== 'other' ||
            get(values, `${name}buttonRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Shape',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['shape'],
          options: [
            { key: 'u_shape', label: 'U shape' },
            { key: 'v_shape', label: 'V shape' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Vest style',
          type: 'select',
          key: ['vestStyle'],
          options: [
            {
              key: 'single_breasted_3_buttons',
              label: 'Single breasted (3 buttons)',
            },
            {
              key: 'single_breasted_4_buttons',
              label: 'Single breasted (4 buttons)',
            },
            {
              key: 'single_breasted_5_buttons',
              label: 'Single breasted (5 buttons)',
            },
            {
              key: 'single_breasted_7_buttons',
              label: 'Single breasted (7 buttons)',
            },
            {
              key: 'double_breasted_4_buttons',
              label: 'Double breasted (4 buttons)',
            },
            {
              key: 'double_breasted_6_buttons',
              label: 'Double breasted (6 buttons)',
            },
            {
              key: 'double_breasted_8_buttons',
              label: 'Double breasted (8 buttons)',
            },
            {
              key: 'others',
              label: 'Others',
            },
          ],
          isRequired: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other Vest style',
          isRequired: true,
          placeholder: '-',
          key: ['otherVestStyle'],
          isHiding: (values, name) =>
            get(values, `${name}vestStyle`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Vest lapel style',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          default: 'no_lapel',
          key: ['vestLapelStyle'],
          options: [
            {
              key: 'notch_lapel',
              label: 'Notch lapel',
            },
            {
              key: 'peak_lapel',
              label: 'Peak lapel',
            },
            {
              key: 'shawl_lapel',
              label: 'Shawl lapel',
            },
            {
              key: 'no_lapel',
              label: 'No lapel',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Vest chest pocket',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          default: 'none',
          key: ['vestChestPocket'],
          options: [
            {
              key: 'none',
              label: 'No chest',
            },
            {
              key: 'left_single',
              label: 'Left single',
            },
            {
              key: 'left_double',
              label: 'Left double',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Vest front pocket',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['vestFrontPocket'],
          options: [
            {
              key: '2cm_width',
              label: '2 cm width',
            },
            {
              key: 'double_bessom',
              label: 'Double bessom',
            },
            {
              key: 'no_pocket',
              label: 'No pocket',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Vest bottom shape',
          type: 'basicRadio',
          isRequired: true,
          key: ['vestBottomShape'],
          options: [
            {
              key: 'cross_arrow',
              label: 'Cross arrow',
            },
            {
              key: 'straight_bottom',
              label: 'Straight bottom',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Contrast fabric',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['contrastFabric'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Contrast fabric ref',
          type: 'select',
          isRequired: true,
          key: ['contrastFabricRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastFabric`, '') === 'no',
          options: [
            { key: 'fe029023', label: 'Black silk (FE029023)' },
            { key: 'fe029025', label: 'Blue silk (FE029025)' },
            { key: 'fp010020', label: 'Red suede (FP010020)' },
            { key: 'fp015782', label: 'Dark blue suede (FP015782)' },
            { key: 'fp010043', label: 'Navy blue suede (FP010043)' },
            { key: 'fp011014', label: 'Brown suede (FP011014)' },
            { key: 'fp010114', label: 'Grey suede (FP010114)' },
            { key: 'fe029024', label: 'White silk (FE029024)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Other contrast fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherContrastFabricRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastFabric`, '') === 'no' ||
            get(values, `${name}contrastFabricRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Contrast position',
          type: 'multiSelect',
          key: ['contrastPositionMulti'],
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}contrastFabric`, '') === 'no',
          options: [
            {
              key: 'chest_pocket',
              label: 'Chest pocket',
            },
            {
              key: 'front_pocket',
              label: 'Front pocket',
            },
            {
              key: 'upper_collar',
              label: 'Upper collar',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          foldable: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const VestMeasureForm = {
  title: 'Final measurement Vest',
  display: 'Vest',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        // {
        //   label: 'Vest length front',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['lengthFrontArrow'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        // {
        //   label: 'Vest length front if straight bottom',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['lengthFrontStraight'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        // {
        //   label: 'Vest length back',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['lengthBack'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },

        {
          label: 'Vest length front Straight',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['lengthFrontStraight'],
          isHiding: (values, name, isSingle) => {
            const keys = name.split('.');
            let path;

            if (isSingle) {
              path = ['orderCustom', 'orderCustomVest', 'vestBottomShape'].join(
                '.'
              );
            } else {
              path = [
                keys[0],
                keys[1],
                'orderCustom',
                'orderCustomVest',
                'vestBottomShape',
              ].join('.');
            }
            // return get(values, path, '') === 'straight_bottom'
            return get(values, path, '') === 'cross_arrow';
          },
          min: 1,
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Vest length back Straight',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['lengthBackStraight'],
          isHiding: (values, name, isSingle) => {
            const keys = name.split('.');
            let path;

            if (isSingle) {
              path = ['orderCustom', 'orderCustomVest', 'vestBottomShape'].join(
                '.'
              );
            } else {
              path = [
                keys[0],
                keys[1],
                'orderCustom',
                'orderCustomVest',
                'vestBottomShape',
              ].join('.');
            }

            // return get(values, path, '') === 'straight_bottom'
            return get(values, path, '') === 'cross_arrow';
          },
          min: 1,
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Vest length front Cross',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['lengthFrontCross'],
          min: 6,
          isHiding: (values, name, isSingle) => {
            const keys = name.split('.');
            let path;

            if (isSingle) {
              path = ['orderCustom', 'orderCustomVest', 'vestBottomShape'].join(
                '.'
              );
            } else {
              path = [
                keys[0],
                keys[1],
                'orderCustom',
                'orderCustomVest',
                'vestBottomShape',
              ].join('.');
            }

            return get(values, path, '') === 'straight_bottom';
            // return get(values, path, '') === 'cross_arrow'
          },
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Vest length back Cross',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['lengthBackCross'],
          min: 6,
          isHiding: (values, name, isSingle) => {
            const keys = name.split('.');
            let path;

            if (isSingle) {
              path = ['orderCustom', 'orderCustomVest', 'vestBottomShape'].join(
                '.'
              );
            } else {
              path = [
                keys[0],
                keys[1],
                'orderCustom',
                'orderCustomVest',
                'vestBottomShape',
              ].join('.');
            }

            return get(values, path, '') === 'straight_bottom';
            // return get(values, path, '') === 'cross_arrow'
          },
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        //
        {
          label: 'Chest',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['chest'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Waist',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['waist'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Hips',
          type: 'number',
          isRequired: true,
          // default: 38,
          placeholder: '-',
          key: ['hips'],
          // min: 25,
          // max: 45,
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};
