import get from 'lodash.get';

export const ShoesDetailsForm = {
  title: 'Shoes details',
  display: 'Shoe',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Shoes design',
          type: 'basicRadio',
          isRequired: true,
          className: 'shoes-radio',
          key: ['design'],
          options: [
            {
              key: 'oxford_one_cut_pointed',
              label: 'Oxford one cut (pointed)',
            },
            {
              key: 'oxford_one_cut_rounded',
              label: 'Oxford one cut (rounded)',
            },
            { key: 'loafer', label: 'Loafer' },
            { key: 'boots_pointed', label: 'Boots (pointed)' },
            { key: 'boots_rounded', label: 'Boots (rounded)' },
            { key: 'sneaker_1', label: 'Sneaker 1' },
            { key: 'sneaker_2', label: 'Sneaker 2' },
            { key: 'double_monk', label: 'Double monk' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            // row: 2,
            size: 2,
          },
        },
        {
          label: 'Front top',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['frontTop'],
          isHiding: (values, name) =>
            (get(values, `${name}design`, '') !== 'oxford_one_cut_pointed' &&
              get(values, `${name}design`, '') !== 'oxford_one_cut_rounded' &&
              get(values, `${name}design`, '') !== 'boots_pointed' &&
              get(values, `${name}design`, '') !== 'boots_rounded') ||
            get(values, `${name}design`, '') === 'sneaker_1',
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            // row: 3,
            size: 1,
          },
        },
        {
          label: 'Material',
          type: 'basicRadio',
          isRequired: true,
          key: ['material'],
          isHiding: (values, name) =>
            get(values, `${name}design`, '') === 'sneaker_1',
          options: [
            {
              key: 'suede',
              label: 'Suede',
            },
            {
              key: 'leather',
              label: 'Leather',
            },
          ],
          pos: {
            col: 1,
            // row: 3,
            size: 1,
          },
        },
        {
          label: 'If suede, color ref',
          type: 'select',
          isRequired: true,
          key: ['suedeColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}material`, '') !== 'suede' ||
            get(values, `${name}design`, '') === 'sneaker_1',
          options: [
            { key: 's1', label: 'S1 - Black' },
            { key: 's2', label: 'S2 - Navy blue' },
            { key: 's3', label: 'S3 - Dark blue' },
            { key: 's4', label: 'S4 - Camel' },
            { key: 's5', label: 'S5 - Wine' },
            { key: 's6', label: 'S6 - Dark brown' },
            { key: 's7', label: 'S7 - Olive' },
            { key: 's8', label: 'S8 - Green' },
            { key: 's9', label: 'S9 - lighter brown' },
            { key: 'other', label: 'Other' },
          ],
          pos: {
            col: 1,
            // row: 4,
            size: 1,
          },
        },
        {
          label: 'Other suede color',
          key: ['otherSuedeColor'],
          isHiding: (values, name) =>
            get(values, `${name}suedeColorRef`, '') !== 'other' ||
            get(values, `${name}design`, '') === 'sneaker_1',
          isRequired: true,
          pos: {
            col: 1,
            // row: 5,
            size: 1,
          },
        },
        {
          label: 'If leather, pattern',
          type: 'basicRadio',
          isRequired: true,
          key: ['leatherPatternRef'],
          isHiding: (values, name) =>
            get(values, `${name}material`, '') !== 'leather' ||
            get(values, `${name}design`, '') === 'sneaker_1',
          options: [
            {
              key: 'original',
              label: 'Original',
            },
            {
              key: 'ox',
              label: 'Ox',
            },
            {
              key: 'weave',
              label: 'Weave',
            },
            {
              key: 'croco',
              label: 'Croco',
            },
          ],
          pos: {
            col: 1,
            // row: 5,
            size: 1,
          },
        },

        {
          label: 'If leather, color ref',
          type: 'select',
          isRequired: true,
          key: ['leatherColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}material`, '') !== 'leather' ||
            get(values, `${name}design`, '') === 'sneaker_1',
          options: [
            { key: 'l1', label: 'L1 - Blue' },
            { key: 'l2', label: 'L2 - Patina black' },
            { key: 'l3', label: 'L3 - Yellowish-brown' },
            { key: 'l4', label: 'L4 - Wine' },
            { key: 'l5', label: 'L5 - Deep green' },
            { key: 'l6', label: 'L6 - Deep brown' },
            { key: 'l7', label: 'L7 - Brownish-grey' },
            { key: 'l8', label: 'L8 - Blackish grey' },
            { key: 'l9', label: 'L9 - Solid black' },
          ],
          pos: {
            col: 1,
            // row: 6,
            size: 1,
          },
        },

        {
          label: 'Sole confection',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['soleConfection'],
          isHiding: (values, name) =>
            get(values, `${name}design`, '') === 'sneaker_1',
          options: [
            {
              key: 'blake',
              label: 'Blake',
            },
            {
              key: 'good_year',
              label: 'Good year',
            },
          ],
          pos: {
            col: 1,
            // row: 7,
            size: 1,
          },
        },

        {
          label: 'Piping ref',
          type: 'select',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}design`, '').match(
              /\b(boots|sneaker|oxford)\w*\b/
            ),
          key: ['pipingRef'],
          options: [
            { key: 'l1', label: 'L1 - Blue' },
            { key: 'l2', label: 'L2 - Patina black' },
            { key: 'l3', label: 'L3 - Yellowish-brown' },
            { key: 'l4', label: 'L4 - Red Wine' },
            { key: 'l5', label: 'L5 - Deep green' },
            { key: 'l6', label: 'L6 - Deep brown' },
            { key: 'l7', label: 'L7 - Brownish-grey' },
            { key: 'l8', label: 'L8 - Blackish grey' },
            { key: 'l9', label: 'L9 - Solid black' },
          ],
          pos: {
            col: 1,
            // row: 8,
            size: 1,
          },
        },

        {
          label: 'Sole material',
          type: 'basicRadio',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}design`, '') === 'sneaker_1',
          key: ['soleMaterial'],
          options: [
            {
              key: 'leather',
              label: 'Leather',
            },
            {
              key: 'rubber',
              label: 'Rubber',
            },
          ],
          pos: {
            col: 1,
            // row: 9,
            size: 1,
          },
        },
        {
          label: 'Sole',
          type: 'basicRadio',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}design`, '') === 'sneaker_1',
          key: ['sole'],
          options: [
            {
              key: 'welted',
              label: 'Welted',
            },
            {
              key: 'non_welted',
              label: 'Non welted',
            },
          ],
          pos: {
            col: 1,
            // row: 10,
            size: 1,
          },
        },
        {
          label: 'Sole option if leather',
          type: 'basicRadio',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}design`, '') === 'sneaker_1',
          key: ['leatherSoleOption'],
          options: [
            {
              key: 'plain',
              label: 'Plain',
            },
            {
              key: 'anti_slipery',
              label: 'Anti slipery',
            },
          ],
          pos: {
            col: 1,
            // row: 11,
            size: 1,
          },
        },
        {
          label: 'Top reinforcement',
          type: 'basicRadio',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}design`, '') === 'sneaker_1',
          foldable: true,
          key: ['topReinforcement'],
          options: [
            {
              key: 'no',
              label: 'No',
            },
            {
              key: 'gold',
              label: 'Gold',
            },
            {
              key: 'silver',
              label: 'Silver',
            },
          ],
          pos: {
            col: 1,
            // row: 12,
            size: 1,
          },
        },
        {
          label: 'Heels',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          isHiding: (values, name) =>
            get(values, `${name}design`, '') === 'sneaker_1',
          key: ['heels'],
          options: [
            {
              key: 'no',
              label: 'No',
            },
            {
              key: 'heels_2cm',
              label: 'Heels (2cm)',
            },
          ],
          pos: {
            col: 1,
            // row: 2,
            size: 1,
          },
        },
        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          foldable: true,
          isHiding: (values, name) =>
            get(values, `${name}design`, '') === 'sneaker_1',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Sneaker color contrast',
          type: 'basicRadio',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}design`, '') !== 'sneaker_1',
          key: ['sneakerColorContrast'],
          options: [
            {
              key: 'army_green',
              label: 'Army green',
            },
            {
              key: 'dark_green',
              label: 'Dark green',
            },
            {
              key: 'black',
              label: 'Black',
            },
            {
              key: 'dark_blue',
              label: 'Dark blue',
            },
            {
              key: 'flash_pink',
              label: 'Flash pink',
            },
            {
              key: 'light_pink',
              label: 'Light pink',
            },
            {
              key: 'yellow',
              label: 'Yellow',
            },
            {
              key: 'orange',
              label: 'Orange',
            },
            {
              key: 'red',
              label: 'Red',
            },
            {
              key: 'wine_red',
              label: 'Wine red',
            },
            {
              key: 'light_blue',
              label: 'Light blue',
            },
            {
              key: 'cream',
              label: 'Cream',
            },
          ],
        },
      ],
    },
  ],
};

export const ShoesMeasureForm = {
  title: 'Final measurement Foot',
  display: 'Shoe',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: '',
          type: 'basicRadio',
          isRequired: true,
          key: ['shoeSize'],
          options: [
            {
              key: 'readyToWearSize',
              label: 'Ready to wear size',
            },
            {
              key: 'tailorMadeSize',
              label: 'Tailor Made Size',
            },
          ],
        },
        {
          label: 'Standard RTW usual size',
          type: 'number',
          isRequired: false,
          placeholder: '-',
          key: ['footMeasure'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Strong foot',
          type: 'basicRadio',
          isRequired: false,
          key: ['strongFoot'],
          isHiding: (values, name) =>
            get(values, `${name}shoeSize`, '') !== 'tailorMadeSize',
          options: [
            {
              key: 'left',
              label: 'Left',
            },
            {
              key: 'right',
              label: 'Right',
            },
          ],
          pos: {
            col: 1,
            // row: 2,
            size: 1,
          },
        },
        {
          label: 'Left foot length',
          type: 'number',
          isRequired: false,
          placeholder: '-',
          key: ['leftFootLength'],
          isHiding: (values, name) =>
            get(values, `${name}shoeSize`, '') !== 'tailorMadeSize',
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Left foot width',
          type: 'number',
          isRequired: false,
          placeholder: '-',
          key: ['leftFootWidth'],
          isHiding: (values, name) =>
            get(values, `${name}shoeSize`, '') !== 'tailorMadeSize',
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Left foot height',
          type: 'number',
          isRequired: false,
          placeholder: '-',
          key: ['leftFootHeight'],
          isHiding: (values, name) =>
            get(values, `${name}shoeSize`, '') !== 'tailorMadeSize',
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Right foot length',
          type: 'number',
          isRequired: false,
          placeholder: '-',
          key: ['rightFootLength'],
          isHiding: (values, name) =>
            get(values, `${name}shoeSize`, '') !== 'tailorMadeSize',
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Right foot width',
          type: 'number',
          isRequired: false,
          placeholder: '-',
          key: ['rightFootWidth'],
          isHiding: (values, name) =>
            get(values, `${name}shoeSize`, '') !== 'tailorMadeSize',
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Right foot height',
          type: 'number',
          isRequired: false,
          placeholder: '-',
          key: ['rightFootHeight'],
          isHiding: (values, name) =>
            get(values, `${name}shoeSize`, '') !== 'tailorMadeSize',
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        // {
        //   // isRequired: true,
        //   type: 'multiupload',
        //   requestURI: '/files/customer-foot',
        //   storageURI: '/storage/customer-foot',
        //   key: ['pictures'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        //
        {
          // isRequired: true,
          label: 'Right foot',
          type: 'multiupload',
          requestURI: '/files/customer-foot',
          storageURI: '/storage/customer-foot',
          key: ['picturesRight'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          // isRequired: true,
          label: 'Left foot',
          type: 'multiupload',
          requestURI: '/files/customer-foot',
          storageURI: '/storage/customer-foot',
          key: ['picturesLeft'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};
