import moment from 'moment';

const formatNb = (nb) => {
  if (!nb) return '';
  nb = nb.toFixed(2);
  return new Intl.NumberFormat('fr-FR', {}).format(nb);
};

const noInfinite = (nb) => {
  var roundedString = nb.toFixed(2);
  return Number(roundedString);
};

const formatDate = (date, type = 'short') => {
  if (type === 'long') {
    return moment(date).format('YYYY-MM-DD h:mm');
  } else {
    return moment(date).format('YYYY-MM-DD');
  }
};

function objectIndex(obj, is, value) {
  if (typeof is === 'string') return objectIndex(obj, is.split('.'), value);
  else if (is.length === 1 && value !== undefined) return (obj[is[0]] = value);
  else if (is.length === 0) return obj;
  else return objectIndex(obj[is[0]], is.slice(1), value);
}

function downloadCSV(header, data, name) {
  let csvContent = 'data:text/csv;charset=utf-8,';

  if (header) {
    header = header
      .map((row) => row?.toString()?.replaceAll(',', '.'))
      .join(',');
    csvContent += header + '\r\n';
  }

  data.forEach(function (rowArray) {
    let row = rowArray
      .map((row) => row?.toString()?.replaceAll(',', '.'))
      .join(',');
    csvContent += row + '\r\n';
  });

  var hiddenElement = document.createElement('a');
  hiddenElement.href = '' + encodeURI(csvContent);
  hiddenElement.target = '_blank';

  //provide the name for the CSV file to be downloaded
  hiddenElement.download = `${name}.csv`;
  hiddenElement.click();
}

function roundHalf(num) {
  return Math.round(num * 2) / 2;
}

const threeDParsing = (data) => {
  return {
    shoulder: roundHalf(data?.front_params?.back_shoulder_width), // back shoulder width
    chest: roundHalf(data?.volume_params?.chest), // bust girth
    waistJacket: roundHalf(data?.volume_params?.waist_gray), // lower waist (est ce le même champs que pour waist pant)
    stomach: roundHalf(data?.volume_params?.abdomen), // abdomen girth
    hips: roundHalf(data?.volume_params?.low_hips), // hips girth
    biceps: roundHalf(data?.volume_params?.bicep), // upper arm girth
    forearm: roundHalf(data?.volume_params?.elbow_girth), // elbow girth
    wrist: roundHalf(data?.volume_params?.wrist), // wrist girth
    armHole: roundHalf(data?.volume_params?.armscye_girth), // armscye girth
    neck: roundHalf(data?.volume_params?.neck), // knee girth
    waistPant: roundHalf(data?.volume_params?.waist_gray), // lower waist (est ce le même champs que pour waist jacket)
    sideNeckPoint: roundHalf(data?.side_params?.side_neck_point_to_upper_hip), // ??
    thigh: roundHalf(data?.volume_params?.thigh), // tight girth
    knee: roundHalf(data?.volume_params?.knee), // knee girth
    calf: roundHalf(data?.volume_params?.calf), // calf girth
    ankle: roundHalf(data?.volume_params?.ankle), // ??
  };
};

const findLastIndex = (array, callback) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (callback(array[i])) {
      return i;
    }
  }

  return -1;
};

const PRIORITY_MAP = {
  shoes: 1,
  collection: 2,
  suit_3_pieces: 3,
  suit_2_pieces: 4,
  sunglasses: 5,
  belt: 6,
  coat: 7,
  skirt: 8,
  pant: 9,
  jean: 10,
  shirt: 11,
  vest: 12,
  jacket_blazer: 13
}

export {
  formatNb,
  noInfinite,
  formatDate,
  objectIndex,
  downloadCSV,
  threeDParsing,
  roundHalf,
  findLastIndex,
  PRIORITY_MAP
};
